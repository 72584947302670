import http from '@/common/http-common';
import {CONFIG, UTILITY } from '@/common/constant';

class ConfigService {
  get(configType) {
    return http.get(`${CONFIG}/user/${configType}`)
  }

  getAdmin(configType) {
    return http.get(`${CONFIG}/admin/${configType}`)
  }


  update(config) {
    return http.post(`${CONFIG}`, config)
  }

  generateBudget(year) {
    return http.get(`${UTILITY}/budget/generate-by-year?year=${year}`)
  }

  sendEmail(email){
    let formData = new FormData();
    formData.append("recipient", email.recipient);
    formData.append("subject", email.subject);
    return http.post(`${UTILITY}/email/send`, formData);

  }

  deleteSmokeTestPayments(){
    return http.post(`${UTILITY}/payment/delete-smoke-test-payments`)
  }

}

export default new ConfigService();
