<template>
    <span>

                <button class="btn btn-sm btn-action btn-outline-danger"
                        v-if="(action(row.permissions, 'SEND_DOCUMENT') && (paymentReviewFields[row.id]['doc_send_date']?.value == null))" 
                        @click="docControlTimeout(row)">
                  <span class="fe fe-send"></span>
                  <small>Timeout</small>
                </button>

                <button class="btn btn-sm btn-action btn-outline-danger"
                        v-if="action(row.permissions, 'VIEW')" @click="redirect('VIEW', row.id)"><span
                    class="fe fe-eye"></span><small>View</small></button>
                <button class="btn btn-sm btn-action btn-outline-danger"
                        v-if="roleCode == 'administrator'" @click="redirect('ADMIN_EDIT', row.id)"><span
                    class="fe fe-edit"></span><small>Edit Data</small></button>
                <button class="btn btn-sm btn-action btn-outline-danger"
                          v-if="isStatusDraft(row) && row.fakturProsesStatus!=='processing'" @click="redirect('EDIT', row.id)">
                          <span class="fe fe-edit"></span><small>Edit Draft</small></button>    
                <span v-if="!isStatusDraft(row)">
                  <span class="btn btn-sm btn-light"  v-b-tooltip.hover  title='Process scan faktur sedang berlangsung' v-if="row.fakturProsesStatus==='processing'">
                      <i class="fa-solid fa-spin fa-spinner"></i></span>
                  <span v-else>   
                    <button class="btn btn-sm btn-action btn-outline-danger"
                            v-if="action(row.permissions, 'EDIT') && (roleCode != 'viewer_fa') && (roleCode != 'viewer')" @click="redirect('EDIT', row.id)">
                            <span class="fe fe-edit"></span><small>Revisi</small></button>
                  </span>          
                  <button class="btn btn-sm btn-action btn-outline-danger"
                          v-if="(action(row.permissions, 'REVIEW'))" @click="redirect('REVIEW', row.id)"><span
                      class="fe fe-file-text"></span><small>Review</small></button>
                  <a class="btn btn-sm btn-action btn-outline-danger screen" @click="getInfo(row)"
                    v-if="isShowKirimDokumen(row,paymentReviewFields,userId)"><span
                      class="fe fe-printer"></span><small>
                    Print Label Pengiriman</small></a>
                  <button class="btn btn-sm btn-action btn-outline-danger"
                          v-if="action(row.permissions, 'UPLOAD_TAX_SLIP') && !cekBppDoc(row.reviewFields) && !isStatusTerminated(row)" data-bs-toggle="modal"
                          data-bs-target="#uploadModal" @click="getInfoSendDoc(row, 'upload')"><span
                      class="fe fe-upload"></span><small> Upload Bukti Ptg</small></button>
                  <button class="btn btn-sm btn-action btn-outline-success"
                          v-if="action(row.permissions, 'UPLOAD_TAX_SLIP') && cekBppDoc(row.reviewFields) && !isStatusTerminated(row)" data-bs-toggle="modal"
                          data-bs-target="#uploadModal" @click="getInfoSendDoc(row, 'upload')"><span
                      class="fe fe-upload"></span><small> Update Bukti Ptg</small></button>
                  <button class="btn btn-sm btn-action btn-outline-danger"
                          v-if="isShowKirimDokumen(row,paymentReviewFields,userId)" 
                          v-b-modal.modalNonPo
                          @click="getInfoSendDoc(row, 'send')"><span
                      class="fe fe-send"></span><small>
                    Kirim Dokumen</small></button>
                  <button class="btn btn-sm btn-action btn-outline-danger"
                          v-if="action(row.permissions, 'RECEIVE_DOCUMENT') 
                                && ((!row.fastTrack && paymentReviewFields[row.id]['doc_received_date']?.value == null && paymentReviewFields[row.id]['doc_send_date']?.value != null)
                                || (row.fastTrack && paymentReviewFields[row.id]['fast_track_doc_received_date']?.value == null && paymentReviewFields[row.id]['fast_track_doc_send_date']?.value != null))" 
                          v-b-modal.modalNonPo
                          @click="getInfoSendDoc(row, 'confirm_doc_received')"><span
                      class="fe fe-archive"></span><small>
                    Konfirmasi Terima Dokumen</small></button>
                  <button class="btn btn-sm btn-action btn-outline-danger" @click="alertMe(row)"
                          v-if="action(row.permissions, 'ALERT_ME')"><span
                      class="fe fe-bell"></span><small>Alert
                    Me</small></button>
                  </span>
                </span>
</template>

<script setup>

    defineProps(["row","paymentReviewFields","roleCode","userId"])    
</script>
<script>
 function action(permission, value) {
      if (permission != null) {
        if (permission.includes('SUPERUSER')) {
          return true
        } else {
          // return actionArray.some(ar => permission.includes(ar))
          return permission.includes(value)
        }
      }
    }

    function isStatusDraft(row){
      var status=row.paymentProcessStatus.toLowerCase()
      return status=='draft';
    }

    function isStatusTerminated(row){
      var status=row.paymentProcessStatus.toLowerCase()
      return status=='terminated';
    }

    function cekBppDoc(item) {
      const bpp = this.searchObj('bpp_doc', item)
      return (bpp.value != null)
    }

    function searchObj(key, arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].paymentReviewFieldCode === key) {
          return arr[i];
        }
      }
    }

    function isShowKirimDokumen(row,paymentReviewFields,userId)
    {
        return ((action(row.permissions, 'PRINT_LABEL') && (paymentReviewFields[row.id]['doc_send_date']?.value == null)) 
                            || (row.userId == userId && row.paymentProcessStatus == 'Menunggu kirim dokumen') 
                            || (row.userId == userId && !row.fastTrack && (paymentReviewFields[row.id]['doc_send_date']?.value == null))
                            || (row.userId == userId && row.fastTrack && (paymentReviewFields[row.id]['fast_track_doc_send_date']?.value == null)) 
                            || (row.userId == userId && row.paymentProcessStatus == 'Konfirmasi Detail Pengiriman Hardcopy Document (By System)'))
                            && !isStatusTerminated(row);
    }

   




</script>