<template>
    <span>{{ format2Decimal(amount) }}</span>
</template>
<script setup>
    defineProps(["amount"])    
</script>

<script>
    function format2Decimal(number)
    {
        if(number===undefined) return "";
        var str=`${number.toLocaleString()}`;
        if(!str.includes('.'))
            return str+'.00';
        let ary=str.split(".");
        if(ary[1].length==2)
            return str;
        else 
            return str+'0';
    }
</script>